import {Col} from "react-bootstrap";
import {FieldArrayRenderProps, FormikValues} from "formik";
import React, {useEffect, useRef, useState} from 'react';
import {faCopy, faPencilAlt, faSave, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {Entry, LoadType, Task, Timesheet} from '../../pages/Dashboard';
import Field from "./Field";
import Notes from "./Notes";
import RowAction from "./RowAction";
import SelectTypeahead from "./SelectTypeahead";
import {EntryState} from "./TimesheetForm";

type Props = {
    handleRemoveEntry: (timesheetId: number, entryId : number) => void,
    fieldArrayProps: FieldArrayRenderProps,
    iteration: number,
    entry: Entry,
    tasks: Task[],
    formSetFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
    handleAddEntry: (entry: Entry, timesheet: Timesheet) => void;
    timesheet: Timesheet;
    handleSaveEntry: (entry: Entry) => Promise<void>;
    entryState: EntryState | undefined;
    loadType: LoadType;
    resolveUnsavedEntries: (values : FormikValues) => Promise<void>;
    values: FormikValues;
    updateTotalHours: (entry: Entry) => void;
}

const TimeEntryRow = ({
    handleRemoveEntry,
    fieldArrayProps,
    iteration,
    entry,
    formSetFieldValue,
    tasks,
    handleAddEntry,
    timesheet,
    handleSaveEntry,
    entryState,
    loadType,
    resolveUnsavedEntries,
    values,
    updateTotalHours
} : Props) => {
    const [note, setNote] = useState<string>(entry.note ? entry.note : '');
    const [showNote, setShowNote] = useState<boolean>(false);
    const [loadingCopy, setLoadingCopy] = useState<boolean>(false);
    const [savingEntry, setSavingEntry] = useState<boolean>(false);
    const entryRef = useRef<Entry>(entry);
    entryRef.current = entry;

    const convertToApi = () => {
        return {
            ...entryRef.current,
            note: note,
            startTime: values.startTime === '' ? null : values.startTime,
            endTime: values.endTime === '' ? null : values.endTime,
            timesheetDate: timesheet.date
        }
    };

    useEffect(()=>{
        updateTotalHours(entry)
    }, [updateTotalHours, entry])

    const handleSetFormIsModified = async (formIsModified : boolean) => {
        if (entryState) {
            entryState.isModified = formIsModified
            if (entryState.isModified || entryState.windowTimer < 0) {
                window.clearTimeout(entryState.windowTimer);
                entryState.windowTimer = window.setTimeout(async () => {
                    await handleSaveEntry(entryRef.current)
                }, 30000);
            }
        }
    };

    const saveNow = async () => {
        if (entryState && entryState?.windowTimer < 0) {
            window.clearTimeout(entryState.windowTimer);
            entryState.windowTimer = -1;
        }

        await handleSaveEntry(entryRef.current);
    };

    const handleSaveNote = async (note : string) => {
        await handleSetFormIsModified(true);
        setNote(note);
        setShowNote(false);
        formSetFieldValue('entries['+iteration+'].note', note, false);
    };

    const timeSheetEntry = timesheet.entries.find((entryFind) => entryFind.uuid === entry.uuid);
    const timeSheetSubmitted = entry && entry.submitted ? true : false;

    return (
     <>
            <Col lg={1} className="row-action pr-0">
                {(entryState?.isSaving || entryState?.isModified || timeSheetSubmitted) && <RowAction
                    icon={entryState?.isSaving ? null : (entryState?.isModified ? faSave : null)}
                    handleClick={async () => {
                        if (!timeSheetSubmitted) {
                            setSavingEntry(true);
                            await saveNow()
                            setSavingEntry(false);
                        }
                    }}
                    spinner={entryState?.isSaving || savingEntry}
                    divClasses={(!entryState?.isSaving && entryState?.isModified) ? 'bg-success text-white' : ''}
                    disabled={timeSheetSubmitted}
                    addClasses={"mb-2 mb-lg-0 mt-0"}
                    isSave={true}
                />}
            </Col>
            <Col lg={3}>
                <SelectTypeahead
                    arrayName='entries'
                    fieldName='code'
                    label='Task'
                    tasks={tasks}
                    iteration={iteration}
                    handleSetFormIsModified={handleSetFormIsModified}
                    disabled={timeSheetSubmitted}
                />
            </Col>
            <Col lg={1}>
                <Field
                    arrayName='entries'
                    name='quantity'
                    type='number'
                    label='Quantity'
                    iteration={iteration}
                    disabled={timeSheetSubmitted}
                    handleSetFormIsModified={handleSetFormIsModified}
                    min={0}
                    max={24}
                    step="any"
                />
            </Col>
            <Col lg={1} className="row-or">
                <div className="pt-0 mt-0 pt-lg-4 mt-lg-1">or</div>
            </Col>
            <Col lg={2}>
                <Field
                    arrayName='entries'
                    name='startTime'
                    type='time'
                    label='Time In'
                    disabled={timeSheetSubmitted}
                    iteration={iteration}
                    handleSetFormIsModified={handleSetFormIsModified}
                />
            </Col>
            <Col lg={2}>
                <Field
                    arrayName='entries'
                    name='endTime'
                    type='time'
                    label='Time Out'
                    disabled={timeSheetSubmitted}
                    iteration={iteration}
                    handleSetFormIsModified={handleSetFormIsModified}
                />
            </Col>
            <Col lg={1} className="row-action d-flex flex-row">
                <RowAction
                    icon={faCopy}
                    handleClick={async () => {
                        setLoadingCopy(true);
                        await resolveUnsavedEntries(values);

                        handleAddEntry(convertToApi(), timesheet);
                        setLoadingCopy(false);
                    }}
                    spinner={loadingCopy}
                    addClasses={'mt-0'}
                />
                <RowAction
                    icon={faPencilAlt}
                    handleClick={async () => {!timeSheetEntry?.submitted && setShowNote(true)}}
                    disabled={timeSheetSubmitted}
                    addClasses={'mt-0'}
                />
                <Notes
                    value={note}
                    iteration={iteration}
                    handelSaveNote={handleSaveNote}
                    show={showNote}
                    setShowNote={setShowNote}
                />
                <RowAction
                    icon={faTrashAlt}
                    disabled={timeSheetSubmitted}
                    handleClick={async () => {
                        if (!timeSheetSubmitted && entry?.id) {
                            fieldArrayProps.remove(iteration);
                            handleRemoveEntry(timesheet.id, entry.id)
                        }
                    }}
                    addClasses={'mt-0'}
                />
                <Field
                    arrayName='entries'
                    name='note'
                    label='note'
                    type='hidden'
                    iteration={iteration}
                    disabled={false}
                    handleSetFormIsModified={handleSetFormIsModified}
                />
                <Field
                    arrayName='entries'
                    name='note'
                    type='hidden'
                    label='Time Out'
                    disabled={false}
                    iteration={iteration}
                    handleSetFormIsModified={handleSetFormIsModified}
                />
            </Col>
            <Col xs={12}>
                <hr className="d-block d-lg-none" />
            </Col>
        </>
    );
};

export default TimeEntryRow;