import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Auth0Provider } from "@auth0/auth0-react";
import './custom.scss';
import { AppState } from '@auth0/auth0-react/dist/auth0-provider';
import history from "./utils/history";

const onRedirectCallback = (appState : AppState) => {
    history.push(
        appState && appState.returnTo
            ? appState.returnTo
            : window.location.pathname
    );
};

ReactDOM.render(
    <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN!!}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID!!}
        audience={process.env.REACT_APP_AUTH0_AUDIENCE!!}
        redirectUri={window.location.origin}
        onRedirectCallback={onRedirectCallback}
    >
        <App />
    </Auth0Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
