import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconDefinition} from "@fortawesome/fontawesome-common-types";
import Spinner from "react-bootstrap/Spinner";
import {faCheck} from "@fortawesome/free-solid-svg-icons";

type Props = {
    icon: IconDefinition | null | undefined;
    handleClick: () => Promise<void>;
    spinner?: boolean | undefined | null;
    divClasses?: string | undefined;
    disabled?: boolean| undefined;
    addClasses?: string;
    isSave?: boolean;
}

const RowAction = ({
    icon,
    handleClick,
    spinner,
    divClasses,
    disabled,
    addClasses,
    isSave
} : Props) => {

    const formControl = disabled ? 'form-control disabled' : 'form-control';

    return (
        <div>
            <label className="d-none d-lg-block"> &nbsp; </label>
            <div
                className={`${formControl} ${addClasses} row-action-control mr-4 ` +
                    (disabled !== true ? 'cursor-pointer ' : '') + divClasses ?? ''}
                onClick={handleClick}
            >
                {icon && !spinner && <FontAwesomeIcon icon={icon} />}
                {disabled && isSave && <FontAwesomeIcon icon={faCheck} />}
                {spinner && <Spinner animation="border" size="sm" className="icon-spinner"/>}
            </div>
        </div>
    );
};

export default RowAction;