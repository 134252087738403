import {Button as ReactButton, Col, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import {FieldArray, FormikValues} from "formik";
import {faInfoCircle, faPlus} from "@fortawesome/free-solid-svg-icons";
import {Entry, HourLine, JobType, LoadType, Task, Timesheet} from '../../pages/Dashboard';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from 'react';
import TimeEntryRow from "./TimeEntryRow";
import {EntryState} from "./TimesheetForm";
import Spinner from "react-bootstrap/Spinner";
import HourLineRow from "./HourLineRow";
import {v4 as uuidv4} from 'uuid';

type Props = {
    jobNumber: number;
    values: FormikValues,
    tasks: Task[],
    handleRemoveEntry: (timesheetId: number, entryId : number) => void,
    formSetFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
    handleSaveEntry: (entry: Entry) => Promise<void>;
    entryStates: EntryState[];
    jobs: JobType[],
    handleAddEntry: (entry: Entry, timesheet: Timesheet) => void;
    timesheet: Timesheet;
    loadType: LoadType;
    resolveUnsavedEntries: (values : FormikValues) => Promise<void>;
    updateTotalHours: (entry: Entry) => void;
    hourLine: HourLine[];
}

const Job = ({
    jobNumber,
    values,
    handleRemoveEntry,
    formSetFieldValue,
    handleSaveEntry,
    entryStates,
    tasks,
    jobs,
    handleAddEntry,
    timesheet,
    loadType,
    resolveUnsavedEntries,
    updateTotalHours,
    hourLine
} : Props) => {
    const currentJob = jobs.find(job => job.number === jobNumber);

    return (
        <React.Fragment>
            <Row className="bg-light mx-0 mt-3 px-2 pt-4">
                <Col xs={11}>
                    <h3>Job #: {jobNumber} - {currentJob?.name}</h3>
                </Col>
                <Col xs={1} className="d-flex flex-row justify-content-end">
                    {currentJob?.producer && currentJob?.description && <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip id="button-tooltip-2">
                            {currentJob?.producer && <span>Producer Name: {currentJob?.producer}</span>}
                            {currentJob?.producer && currentJob?.description && <br />}
                            {currentJob?.description && <span>Job Description: {currentJob?.description}</span>}
                        </Tooltip>}
                    >
                        <FontAwesomeIcon icon={faInfoCircle} />
                    </OverlayTrigger>}
                </Col>
                <Col xs={12}>
                    <hr className="w-100"/>
                </Col>
            </Row>
            {hourLine.map((h) => {
                return <HourLineRow
                    key={"hourline-row-" + h.id}
                    hourline={h}
                    addHourLine={(h: HourLine) => {
                        handleAddEntry({
                            code: h.code,
                            uuid: uuidv4(),
                            jobNumber: jobNumber,
                            startTime: h.startTime,
                            endTime: h.endTime,
                            submitted: false,
                            approved: false
                        }, timesheet);
                    }}
                />
            })}
            <Row className="bg-light mx-0 px-2">
                <FieldArray
                    name="entries"
                    render={(props) => (
                        <>
                            {values.entries.map((entry : Entry, index : number) => {
                                if (entry.jobNumber === jobNumber) {
                                    return <TimeEntryRow
                                        tasks={tasks}
                                        handleRemoveEntry={handleRemoveEntry}
                                        fieldArrayProps={props}
                                        iteration={index}
                                        entry={entry}
                                        key={entry.uuid}
                                        formSetFieldValue={formSetFieldValue}
                                        timesheet={timesheet}
                                        handleAddEntry={handleAddEntry}
                                        handleSaveEntry={handleSaveEntry}
                                        entryState={entryStates.find((s) => s.entryUUID === entry.uuid)}
                                        loadType={loadType}
                                        resolveUnsavedEntries={resolveUnsavedEntries}
                                        values={values}
                                        updateTotalHours={updateTotalHours}
                                    />
                                }
                                return null;
                            })}
                        </>
                    )}
                />
            </Row>
            <Row className="bg-light mx-0 px-2 pt-2 pb-4">
                <Col xs={12} lg={2}>
                    <div>
                        <ReactButton
                            variant="primary"
                            onClick={ async () => {
                                await resolveUnsavedEntries(values);

                                handleAddEntry({
                                    id: 9999,
                                    uuid: '9999',
                                    jobNumber: jobNumber,
                                    submitted: false,
                                    approved: false
                                }, timesheet);
                            }}
                            disabled={loadType.loading && (loadType.type === 'add' || loadType.type === 'copy')}
                            className="d-flex flex-row justify-content-center"
                        >
                            {loadType.loading && (loadType.type === 'add' || loadType.type === 'copy') ? (
                                <React.Fragment>
                                    <Spinner animation="border" size="sm" className="pr-1 mt-1"/>
                                    &nbsp;Add Entry
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <FontAwesomeIcon icon={faPlus} className="pr-1 mt-1" />
                                    &nbsp;Add Entry
                                </React.Fragment>
                            )}
                        </ReactButton>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default Job;