import React, {useState} from 'react';
import {Button,  Modal} from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import {Typeahead} from "react-bootstrap-typeahead";
import {Entry, JobType, Timesheet} from "../../pages/Dashboard";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Spinner from "react-bootstrap/Spinner";
import {FormikValues} from "formik";

type Props = {
    show: boolean;
    setShowAddEntry: (showAddEntry: boolean) => void;
    jobs: JobType[];
    handleAddEntry: (entry: Entry, timesheet: Timesheet) => Promise<void>;
    timesheet: Timesheet;
    resolveUnsavedEntries: (values : FormikValues) => Promise<void>;
    values: FormikValues;
}

const AddEntryModal = ({
    show,
    setShowAddEntry,
    jobs,
    handleAddEntry,
    timesheet,
    resolveUnsavedEntries,
    values
} : Props) => {
    const [job, setJob] = useState<JobType | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [noJobError, setNoJobError] = useState<boolean>(false);

    const handleSelectJob = (selected: { [x: string]: {}; }[]) => {
        //@ts-ignore
        setJob(selected[0]);
        setNoJobError(false);
    };

    const handleAddEntryClick = async () => {

        if (job) {
            setLoading(true);
            await resolveUnsavedEntries(values);
            await handleAddEntry({
                id: 9999,
                uuid: '9999',
                jobNumber: job.number,
                submitted: false,
                approved: false
            }, timesheet);
            setLoading(false);
            setShowAddEntry(false);
        } else {
            setNoJobError(true);
        }
    };

    return (
        <>
            <Modal show={show} onHide={() => {setShowAddEntry(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Timesheet Entry</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Row>
                            <Form.Group className="w-100">
                                <Form.Label>Jobs</Form.Label>
                                <Typeahead
                                    id={"jobs-typeahead"}
                                    labelKey="name"
                                    onChange={handleSelectJob}
                                    options={jobs}
                                    placeholder="Select job for entry..."
                                    multiple={false}
                                />
                            </Form.Group>
                            {noJobError && (
                                <p className="m-0 p-0 alert-warning">You must select a job above.</p>
                            )}
                        </Form.Row>
                    </Form.Group>
                    <div className="d-flex flex-row w-100 justify-content-end">
                        <Button variant="secondary" className="mr-2" onClick={() => setShowAddEntry(false)}>
                            Cancel
                        </Button>
                        <Button
                            variant="primary"
                            onClick={handleAddEntryClick}
                            className="d-flex flex-row"
                            disabled={loading}
                        >
                            {loading ? (
                                <React.Fragment>
                                    <Spinner animation="border" size="sm" className="mt-1"/>
                                    &nbsp;Add Entry
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <FontAwesomeIcon icon={faPlus} className="pr-1 mt-1" />
                                    &nbsp;Add Entry
                                </React.Fragment>
                            )}
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default AddEntryModal;