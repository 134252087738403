import React, { useState } from 'react';

import Form from "react-bootstrap/Form";
import { Task } from "../../pages/Dashboard";
import { Typeahead } from 'react-bootstrap-typeahead';
import { useFormikContext, FormikContextType, FormikValues } from 'formik';

type Props = {
    arrayName: string,
    fieldName: string,
    iteration: number,
    tasks: Task[],
    label?: string,
    value?: string,
    handleSetFormIsModified?: (formIsModified: boolean) => Promise<void>
    disabled?: boolean;
}

const SelectTypeahead = ({
    arrayName,
    fieldName,
    iteration,
    label,
    value,
    tasks,
    handleSetFormIsModified,
    disabled
}: Props) => {
    const { values, setFieldValue, errors }: FormikContextType<FormikValues> = useFormikContext();
    const localValue = value ? value : values[arrayName][iteration][fieldName];
    const foundTask = tasks.find((task: Task) => task.code === localValue);
    const [
        selectedTask,
        setSelectedTask
    ] = useState<Task[]>('' === value || undefined === foundTask ? [] : [foundTask]);

    // @ts-ignore
    const errorsName = arrayName && iteration !== undefined && errors[arrayName] && errors[arrayName][iteration] ? errors[arrayName][iteration][fieldName] : errors[fieldName];

    const handleSelected = async (tasks: Task[]) => {
        setSelectedTask(tasks);

        if (tasks[0]) {
            setFieldValue(`entries.${iteration}.code`, tasks[0].code);
        }

        handleSetFormIsModified && await handleSetFormIsModified(true);
    }

    return (
        <Form.Group>
            <Form.Label>{label}</Form.Label>
            <Typeahead
                id={label + "-typeahead"}
                labelKey="description"
                onChange={handleSelected}
                options={tasks}
                placeholder="Choose..."
                selected={selectedTask}
                multiple={false}
                isInvalid={!!errorsName}
                inputProps={{
                    className: 'form-control ' + (!!errorsName ? 'is-invalid' : ''),
                }}
                disabled={disabled}
            />
            {!!errorsName && <div style={{
                display:'block',
                color: '#dc3545',
                fontFamily: '"Poppins-Regular", sans-serif',
                fontWeight: 400,
                fontSize: '11.5px'
            }}>Required</div>}
        </Form.Group>
    );
};

export default SelectTypeahead;