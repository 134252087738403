import {Alert, Col, Container, Row} from "react-bootstrap";
import {DateTimeFormatter, LocalDate} from '@js-joda/core';
import React, {useCallback, useEffect, useState} from 'react';
import ViewBy, {DateRange, getWeekRange} from "../components/navigation/ViewBy";
import CircularLoadingIndicator from "../components/CircularLoadingIndicator";
import NavHeader from "../components/navigation/NavHeader";
import TimesheetForm from "../components/time/TimesheetForm";
import {withAuthenticationRequired} from "@auth0/auth0-react";
import {apiEndpoint, useApiFetch} from '../components/Providers/Auth0Provider';

export type Entry = {
    id?: number;
    uuid: string;
    jobNumber: number;
    uniqueNumber?: number;
    code?: string;
    quantity?: number;
    startTime?: string | null;
    endTime?: string | null;
    note?: string;
    submitted: boolean;
    approved: boolean;
};

export type Timesheet = {
    id: number;
    uuid: string;
    timesheetNumber?: string;
    date: LocalDate;
    entries: Array<Entry>;
    submitted: boolean;
    totalHours : number;
};

export type HourLine = {
    id: number,
    date: LocalDate,
    jobNumber: number,
    room: string,
    status: string,
    startTime: string,
    endTime: string,
    code: string,
}

export type Task = {
    id: string;
    code: string;
    description: string;
    active: boolean
};

export type JobType = {
    id: string,
    number: number;
    name: string;
    active: string;
    producer:string;
    description:string;
};

export type LoadTypeType = 'copy' | 'add' | 'full';

export type LoadType = {
    type : LoadTypeType;
    loading : boolean;
};

const dateFormatter = DateTimeFormatter.ofPattern('MM/dd/yyyy');

const mapRawTimesheet = (rawTimesheet: any) : Timesheet => ({
    ...rawTimesheet,
    date: LocalDate.parse(rawTimesheet.date, dateFormatter)
});

const mapRawTask = (rawTask: any): Task => ({
    ...rawTask,
});

const mapRawJob = (rawJob: any): JobType => ({
    ...rawJob,
});

const loadHourLine = async (
    apiFetch: (url: string, init ?: RequestInit) => Promise<Response>,
    setHourLines: (hourLines: HourLine[]) => void,
    dateRange : DateRange
) : Promise<void> => {
    const url = new URL('/v1/hourline', apiEndpoint);
    url.searchParams.set('dateStart', dateRange.start.toString());
    url.searchParams.set('dateEnd', dateRange.end.toString());
    const response = await apiFetch(url.toString());
    const data = await response.json();
    const newTasks = data.map((rawHour: any): HourLine => ({
        ...rawHour,
        date: LocalDate.parse(rawHour.date, dateFormatter)
    }));

    setHourLines(newTasks);
};

const Dashboard = () => {
    const [timesheets, setTimesheets] = useState<Timesheet[] | null>(null);
    const [tasks, setTasks] = useState<Task[] | null>(null);
    const [jobs, setJobs] = useState<JobType[] | null>(null);
    const [hourLines, setHourLines] = useState<HourLine[]>([]);
    const [dateRange, setDateRange] = useState<DateRange | null>(getWeekRange(0, 0));
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [loadType, setLoadType] = useState<LoadType>({
        type : 'copy',
        loading : false
    });
    const [isInitialized, setIsInitialized] = useState<boolean>(false);

    const apiFetch = useApiFetch();

    const loadTasks = useCallback(async () => {
        const url = new URL('/v1/tasks', apiEndpoint);
        const response = await apiFetch(url.toString());
        const data = await response.json();
        const newTasks = data.map(mapRawTask).filter((task: Task) => '' !== task.description);
        newTasks.sort((a: Task, b: Task) => a.description.localeCompare(b.description));

        setTasks(newTasks);
    }, [apiFetch, setTasks]);

    const loadJobs = useCallback(async () => {
        const url = new URL('/v1/jobs', apiEndpoint);
        const response = await apiFetch(url.toString());
        const data = await response.json();

        setJobs(data.map(mapRawJob));
    }, [apiFetch]);

    const loadTimesheets = useCallback(async (dateRange : DateRange, loadType : LoadTypeType) => {
        if (loadType !== 'full') {
            setLoadType({
                type: loadType,
                loading: true
            });
        }

        const url = new URL('/v1/timesheet', apiEndpoint);
        url.searchParams.set('dateStart', dateRange.start.toString());
        url.searchParams.set('dateEnd', dateRange.end.toString());

        const response = await apiFetch(url.toString());
        const data = await response.json();

        setTimesheets(null);
        setTimesheets(data.map(mapRawTimesheet));

        if (loadType !== 'full') {
            setLoadType({
                type: loadType,
                loading: false
            });
        }
    }, [setTimesheets, apiFetch]);

    const postEntry = useCallback(async (entry : Entry, timesheet : Timesheet, loadType: LoadTypeType) => {
        let newTimesheet : Timesheet | null = null;

        if (!timesheet.id) {
            const url = new URL('/v1/timesheet', apiEndpoint);
            const response = await apiFetch(url.toString(), {
                method: 'POST',
                body: JSON.stringify(timesheet)
            });
            const rawTimesheet = await response.json();
            newTimesheet = mapRawTimesheet(rawTimesheet);
            timesheet.id = newTimesheet.id;
            timesheet.uuid = newTimesheet.uuid;
            timesheet.timesheetNumber = newTimesheet.timesheetNumber;
        }

        let url = new URL(`/v1/entries/${timesheet.timesheetNumber}`, apiEndpoint);
        
        if (newTimesheet !== null) {
            url = new URL(`/v1/entries/${newTimesheet.timesheetNumber}`, apiEndpoint);
        }

        await apiFetch(url.toString(), {
            method: 'POST',
            body: JSON.stringify({
                ...entry,
                timesheetDate: timesheet.date
            })
        });

        if (dateRange) {
            await loadTimesheets(dateRange, loadType);
        }
    }, [apiFetch, dateRange, loadTimesheets]);

    const initializeData = useCallback( async () => {
        setIsInitialized(true);
        loadJobs();
        loadTasks();
        if (dateRange) {
            loadTimesheets(dateRange, 'full');
            loadHourLine(apiFetch, setHourLines, dateRange);
        }
    }, [loadJobs, loadTasks, dateRange, loadTimesheets, apiFetch]);

    const handleTimesheetSubmit = async (data: {timesheet: Timesheet, entries: Entry[]}) => {
        data.entries.forEach(entry => {
            const job = jobs?.find(job => job.number === entry.jobNumber);

            if(job === undefined || !job.active) {
                setErrorMessage('ERROR: Job is inactive and cannot be submitted.');
            }
        });

        if (dateRange) {
            await loadTimesheets(dateRange, 'full');
        }
    };

    const handleAddEntry = async (entry: Entry, timesheet: Timesheet) => {
        await postEntry(entry, timesheet, 'add');
    };

    const handleChangeDateRange = async (dateRange : DateRange) => {
        setDateRange(dateRange);
        await loadTimesheets(dateRange, 'full');
    };

    const handleRemoveEntry = async (timesheetId: number, entryId: number) => {
        const url = new URL('/v1/entries/' + entryId, apiEndpoint);
        const response = await apiFetch(url.toString(), {
            method: 'DELETE',
        });

        if (!response.ok) {
            setErrorMessage('ERROR: The entry could not be removed.');
            return;
        }

        const newTimesheets = timesheets ? timesheets.map((timesheet : Timesheet) => {            
            if(timesheet.id === timesheetId) {
                timesheet.entries = timesheet.entries.filter((entry: Entry) => {
                    if(entry.id === entryId) {
                        return null;
                    }

                    return entry;
                });

                return timesheet;
            }
            
            return timesheet;
        }) : [];

        setTimesheets(newTimesheets);
    };

    useEffect(() => {
        if (!isInitialized) {
            initializeData();
        }
    }, [isInitialized, initializeData]);

    return (
        <React.Fragment>
            <NavHeader />
            {dateRange && (
                <ViewBy onChange={handleChangeDateRange} dateRange={dateRange} />
            )}
            <Container>
                {errorMessage && <Alert className="alert-fixed" key='error' variant='danger' onClose={() => setErrorMessage(null)} dismissible>
                    {errorMessage}
                </Alert>}
                {timesheets && tasks && jobs && dateRange ? timesheets.map((timesheet: Timesheet) => {
                    return <TimesheetForm
                        key={timesheet.uuid}
                        handleTimesheetSubmit={handleTimesheetSubmit}
                        timesheet={timesheet}
                        tasks={tasks}
                        handleRemoveEntry={handleRemoveEntry}
                        jobs={jobs}
                        handleAddEntry={handleAddEntry}
                        setErrorMessage={setErrorMessage}
                        loadType={loadType}
                        hourLines={hourLines.filter((h) => h.date.equals(timesheet.date))}
                    />
                }) : (
                    <Row>
                        <Col xs={12} className="bg-white rounded p-4">
                            <CircularLoadingIndicator />
                        </Col>
                    </Row>
                )}
            </Container>
        </React.Fragment>
    );
};

export default withAuthenticationRequired(Dashboard, {
    onRedirecting: () => <CircularLoadingIndicator />,
});
  