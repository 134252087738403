import React from 'react';
import NavBase from './NavBase';
import { useAuth0 } from '@auth0/auth0-react';
import { Nav } from 'react-bootstrap';

const NavHeader = () => {
    const {
        isAuthenticated,
        loginWithRedirect,
        logout,
    } = useAuth0();


    return <NavBase>
        <div className="text-right mt-3 d-flex flex-row">
            {!isAuthenticated && (
                <Nav.Link
                    // href="/dashboard"
                    onClick={() => loginWithRedirect()}
                >
                    Login
                </Nav.Link>
            )}
            {isAuthenticated && (
                <Nav.Link
                    // href="/dashboard"
                    onClick={() => logout({ returnTo: window.location.origin })}
                >
                    Logout
                </Nav.Link>
            )}
        </div>
    </NavBase>
};

export default NavHeader;