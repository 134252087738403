import {Col, Row} from "react-bootstrap";
import React from 'react';
import {HourLine} from '../../pages/Dashboard';
import RowAction from "./RowAction";
import {faPlus} from "@fortawesome/free-solid-svg-icons";

type Props = {
    hourline: HourLine;
    addHourLine: (h : HourLine) => void;
}

const HourLineRow = ({
    hourline,
    addHourLine
} : Props) => {
    return (
        <Row className="bg-light mx-0 px-2" id={`hourline-${hourline.id}`}>
            <Col lg={1} className="row-action">

            </Col>
            <Col lg={2}>
                <label className="d-none d-lg-block">Status</label>
                {hourline.status}
            </Col>
            <Col lg={2}>
                <label className="d-none d-lg-block">Room</label>
                {hourline.room}
            </Col>
            <Col lg={1} className="row-or">

            </Col>
            <Col lg={2} >
                <label className="d-none d-lg-block">Start Time</label>
                {hourline.startTime}
            </Col>
            <Col lg={2}>
                <label className="d-none d-lg-block">End Time</label>
                {hourline.endTime}
            </Col>
            <Col lg={2} >
                <RowAction
                    icon={faPlus}
                    handleClick={async () => {
                        addHourLine(hourline);
                    }}
                />
            </Col>
        </Row>
    );
};

export default HourLineRow;