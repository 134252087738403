import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

const CircularLoadingIndicator = () => {
    return (
            <div className="d-flex flex-column w-100 mt-5 align-items-center">
                <Spinner animation="grow"/>
                <h3 className="pt-2 pl-3">Loading ...</h3>
            </div>
    );
};

export default CircularLoadingIndicator;