import React from 'react';
import {BrowserRouter, Route} from 'react-router-dom';
import Dashboard from "./pages/Dashboard";
import Auth0Provider from './components/Providers/Auth0Provider';
import FullPageLoadingIndicator from './components/FullPageLoadingIndicator';
import {baseUrl} from './utils/Helpers';
import {useAuth0} from "@auth0/auth0-react";

const Guard = () => {
    const auth0 = useAuth0();

    if (auth0.isLoading || auth0.user === null) {
        return <FullPageLoadingIndicator />;
    }

    return <Route path="/" exact component={Dashboard} />;
};

const App = () => {
    return (
        <React.Fragment>
            <BrowserRouter>
                <Auth0Provider auth0Options={{
                    domain: process.env.REACT_APP_AUTH0_DOMAIN!!,
                    client_id: process.env.REACT_APP_AUTH0_CLIENT_ID!!,
                    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
                    redirect_uri: baseUrl,
                    cacheLocation: 'localstorage',
                }}>
                    <Guard />
                </Auth0Provider>
            </BrowserRouter>
        </React.Fragment>
    );
}

export default App;
