import React from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Logo from '../branding/Logo';

const NavBase = (props : any) => {
    return (
        <Navbar expand={true} className="bg-white shadow-sm">
            <Container className="d-flex align-items-start">
                <Navbar.Brand href="#">
                    <Logo/>
                </Navbar.Brand>
                {props.children}
            </Container>
        </Navbar>
    );
}

export default NavBase;
