import { ChronoField, LocalDate, convert, nativeJs } from "@js-joda/core";
import { Col, Container, Nav, Row } from "react-bootstrap";
import React, { useEffect, useState } from 'react';

import DatePicker from "../calendar/DatePicker";

export type DateRange = {
    start: LocalDate;
    end: LocalDate;
};

type Props = {
    dateRange: DateRange;
    onChange: (range: DateRange) => Promise<void>;
}

const ViewBy = ({ dateRange, onChange }: Props) => {
    const [start, setStart] = useState<Date | null>(null);
    const [end, setEnd] = useState<Date | null>(null);
    const [searchType, setSearchType] = useState<string>('current');
    const [runSearch, setRunSearch] = useState<boolean>(false);

    useEffect(() => {
        setStart(convert(dateRange.start).toDate());
        setEnd(convert(dateRange.end).toDate());
    }, [dateRange]);

    useEffect(() => {
        if (null !== start && null !== end) {
            const currentWeek = getWeekRange(0, 0);
            const today = new Date();
            today.setHours(0, 0, 0, 0);

            if (convert(currentWeek.start).toDate().toString() === start.toString() && convert(currentWeek.end).toDate().toString() === end.toString()) {
                setSearchType('current');

            } else if (today.toString() === start.toString() && today.toString() === end.toString()) {
                setSearchType('today');

            } else {
                setSearchType('custom');
            }
        }
    }, [start, end]);

    useEffect(() => {
        if (runSearch) {
            if (!start || isNaN(start.valueOf())) {
                return;
            }

            if (!end || isNaN(end.valueOf())) {
                return;
            }

            let startDate = LocalDate.from(nativeJs(start));
            const endDate = LocalDate.from(nativeJs(end));

            if (endDate < startDate) {
                startDate = endDate;
            }

            setRunSearch(false);
            onChange({ start: startDate, end: endDate });
        }
    }, [runSearch, start, end, onChange]);

    const setCurrentWeek = () => {
        const currentWeek = getWeekRange(0, 0);
        setStart(convert(currentWeek.start).toDate());
        setEnd(convert(currentWeek.end).toDate());
        setSearchType('current');
        setRunSearch(true);
    }

    const setToday = () => {
        setStart(new Date());
        setEnd(new Date());
        setSearchType('today');
        setRunSearch(true);
    }

    const setCustom = () => {
        setSearchType('custom');
    }

    const handleDateStartChange = (date: Date | null) => {
        setStart(date);

        if (!date || isNaN(date.valueOf())) {
            return;
        }

        const start = LocalDate.from(nativeJs(date));
        let end = dateRange.end;

        if (end < start) {
            end = start;
            setEnd(date);
        }

        onChange({start, end: end});
    };

    const handleDateEndChange = (date : Date | null) => {
        setEnd(date);

        if (!date || isNaN(date.valueOf())) {
            return;
        }

        const end = LocalDate.from(nativeJs(date));
        let start = dateRange.start;

        if (end < start) {
            start = end;
            setStart(date);
        }

        onChange({ start, end });
    };

    return (
        <Container>
            <Row className="py-2 mt-2 pl-0">
                <Col xs={12} lg={6} xl={6} className="d-flex flex-row pt-2">
                    <Nav>
                        <Nav.Item>
                            <Nav.Link eventKey="disabled" disabled>
                                View By:
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link href="#" className={searchType === 'current' ? 'font-weight-bold' : ''} onClick={setCurrentWeek}>Current Week</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link href="#" className={searchType === 'today' ? 'font-weight-bold' : ''} onClick={setToday}>Today</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link href="#" className={searchType === 'custom' ? 'font-weight-bold' : ''} onClick={setCustom}>Custom</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Col>
                <Col
                    xs={12}
                    lg={6}
                    xl={6}
                    className="d-flex flex-column flex-lg-row justify-content-start justify-content-lg-end py-1"
                >
                    <div className="mt-1">From: </div>
                    <DatePicker handleDateChange={handleDateStartChange} currentDate={start ? start : new Date()}/>
                    <div className="mt-1 ml-3">To: </div>
                    <DatePicker handleDateChange={handleDateEndChange} currentDate={end ? end : new Date()}/>
                </Col>
            </Row>
        </Container>
    );
}

export type FirstDayOfWeek = 0 | 1 | 2 | 3 | 4 | 5 | 6;;

export const getWeekRange = (offset : number, firstDayOfWeek : FirstDayOfWeek) => {
    let end = LocalDate.now();

    if (firstDayOfWeek === 0) {
        end = end.with(ChronoField.DAY_OF_WEEK, 6);
    } else if (firstDayOfWeek === 1) {
        end = end.with(ChronoField.DAY_OF_WEEK, 7);
    } else {
        end = end.plusDays(7).with(ChronoField.DAY_OF_WEEK, firstDayOfWeek - 1);
    }

    end = end.plusDays(offset * 7);
    return {start: end.minusDays(6), end};
};

export default ViewBy;