import React from "react";
import {Form, InputGroup} from "react-bootstrap";

type Props = {
    handleDateChange: (date : Date | null) => void;
    currentDate: Date;
}

function formatDate(d: Date) {
    let month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

const DatePicker = ({handleDateChange, currentDate} : Props) => {
    return (
        <label>
            <InputGroup className="m-0 px-1">
                <Form.Control type="date"
                    value={formatDate(currentDate)}
                    onChange={(e) =>  {
                        if (e.target.value) {
                            const newDate = new Date(e.target.value + 'T00:00:00');
                            handleDateChange(newDate);
                        }
                    }} />
            </InputGroup>
        </label>
    );
}

export default DatePicker;