import * as yup from "yup";
import {Button as ReactButton, Col, Row} from "react-bootstrap";
import {Form, Formik, FormikValues} from "formik";
import {faCheck, faExclamation, faPlus} from "@fortawesome/free-solid-svg-icons";
import {DateTimeFormatter} from "@js-joda/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Job from "./Job";
import {Locale} from "@js-joda/locale_en-us";
import NoRecords from '../../images/norecords.png';
import React, {useCallback, useEffect, useState} from 'react';
import {Entry, HourLine, JobType, LoadType, Task, Timesheet} from "../../pages/Dashboard";
import AddEntryModal from "./AddEntryModal";
import {apiEndpoint, useApiFetch} from "../Providers/Auth0Provider";
import Spinner from "react-bootstrap/Spinner";
import moment from "moment";
import {differenceInMinutes} from "date-fns";


export interface NameToValueMap
{
    [key: string]: any;
}

export type EntryState = {
    entryUUID: string;
    isModified: boolean;
    isSaving: boolean;
    windowTimer: number;
}

type Props = {
    handleTimesheetSubmit: (values : any) => Promise<void>;
    timesheet: Timesheet;
    tasks: Task[];
    jobs: JobType[];
    handleRemoveEntry: (timesheetId: number, entryId : number) => void;
    handleAddEntry: (entry: Entry, timesheet: Timesheet) => Promise<void>;
    setErrorMessage: (message: string|null) => void;
    loadType: LoadType;
    hourLines: HourLine[];
}

const calculateTotalHours = (entries : Array<Entry>) : number => {
    return entries.reduce((sum, current) => {
        if (current.quantity) {
            return sum+current.quantity;
        }
        if (current.startTime && current.endTime) {
            const min : string = (differenceInMinutes(
                new Date('1970-01-01T' + current.endTime),
                new Date('1970-01-01T' + current.startTime)
            )/60).toFixed(2);
            return sum + parseFloat(min);
        }
        return sum;
    }, 0);
};

const dateFormatter = DateTimeFormatter.ofPattern('EEEE - LLLL dd').withLocale(Locale.US);

const TimesheetForm = ({
    handleTimesheetSubmit,
    timesheet,
    handleRemoveEntry,
    handleAddEntry,
    tasks,
    jobs,
    setErrorMessage,
    loadType,
    hourLines
} : Props) => {
    const [showAddEntry, setShowAddEntry] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [entryStates, setEntryStates] = useState<EntryState[]>([]);
    const [totalHours, setTotalHours] = useState<number>(0);
    const apiFetch = useApiFetch();

    useEffect(() => {
        setEntryStates(timesheet.entries.map((e) => {
            return {
                entryUUID: e.uuid,
                isSaving: false,
                isModified: false,
                windowTimer: -1
            }
        }));

        setTotalHours(calculateTotalHours(timesheet.entries));
    }, [timesheet.entries]);

    const handleShowAddEntry = () => {
        setShowAddEntry(true);
    };

    let uniqueJobs : Array<number> = [];

    timesheet.entries?.forEach(function (entry) {
        let i = uniqueJobs.findIndex(b  =>  b === entry.jobNumber);

        if (i <= -1) {
            uniqueJobs.push(entry.jobNumber);
        }
    });
    hourLines.forEach((e) => {
        const jn = e.jobNumber;
        if (uniqueJobs.findIndex(b  =>  b === jn) < 0) {
            uniqueJobs.push(jn);
        }
    })

    const updateTotalHours = (entry: Entry) => {
        const entriesIdx = timesheet.entries.findIndex((e) => e.uuid === entry.uuid);
        const quantity = (entry.quantity ? parseFloat(String(entry.quantity)) : undefined);
        timesheet.entries[entriesIdx] = {...entry, quantity: quantity};
        setTotalHours(calculateTotalHours(timesheet.entries));
    }

    const initialValues : NameToValueMap = {
        timesheet: timesheet,
        entries : timesheet.entries
    };

    const schema = yup.object({
        entries: yup.array()
            .of(
                yup.object().shape({
                    code: yup.string().required('Required'), // these constraints take precedence
                    quantity: yup.string().test(
                        'oneOfRequired',
                        'Required',
                        function () {
                            return (this.parent.quantity > 0 || (this.parent.startTime && this.parent.endTime))
                        }
                    ),
                    startTime: yup.string().when('quantity', {
                        is: (quantity) => quantity === undefined || quantity <= 0,
                        then: yup.string().required('Required'),
                        otherwise: yup.string()
                    }),
                    endTime: yup.string().test(
                        'greaterThanStartTime',
                        'Time Out must be greater than Time In',
                        function (value : any) {
                            if (value && this.parent.startTime) {
                                const time_obj = moment(value, "HH:mm:ss");
                                return time_obj.isSameOrAfter(moment(this.parent.startTime, "HH:mm:ss"));
                            }

                            return true;
                        }
                    ),
                })
            )
    });

    const setEntryIndexSaving = useCallback((index: number, isSaving: boolean) => {
        entryStates[index].isSaving = isSaving;
        setEntryStates([]);
        setEntryStates(entryStates);
    }, [entryStates]);

    const handleSaveEntry = useCallback(async (entry: Entry) : Promise<void> => {
        const entriesIdx = timesheet.entries.findIndex((e) => e.uuid === entry.uuid);
        const quantity = (entry.quantity ? parseFloat(String(entry.quantity)) : undefined);
        timesheet.entries[entriesIdx] = {...entry, quantity: quantity};
        setTotalHours(calculateTotalHours(timesheet.entries));

        const index = entryStates.findIndex((e) => e.entryUUID === entry.uuid);

        if (index >= 0) {
            if (entryStates[index].windowTimer > 0) {
                window.clearTimeout(entryStates[index].windowTimer);
                entryStates[index].windowTimer = -1;
            }
            setEntryIndexSaving(index, true);
        }
        try {
            const url = new URL(`/v1/entries/${timesheet.timesheetNumber}`, apiEndpoint);

            await apiFetch(url.toString(), {
                method: 'PUT',
                body: JSON.stringify({
                    ...entry,
                    startTime: entry.startTime === '' ? null : entry.startTime,
                    endTime: entry.endTime === '' ? null : entry.endTime,
                    timesheetDate: timesheet.date
                })
            });
            if (index >= 0) {
                entryStates[index].isModified = false;
                setEntryIndexSaving(index, false);
            }
        } catch (e) {
            setEntryIndexSaving(index, false);
            setErrorMessage('ERROR: There was an error saving the time entry');
            throw e;
        }
    }, [
        apiFetch,
        entryStates,
        setEntryIndexSaving,
        setTotalHours,
        setErrorMessage,
        timesheet.date,
        timesheet.timesheetNumber,
        timesheet.entries
    ]);

    const submitTimeSheet = async (values: FormikValues) => {
        setIsSubmitting(true);
        const modifiedStates = entryStates.filter((e) => e.isModified);
        if (modifiedStates.length > 0) {
            const savePromises = modifiedStates.map((s) =>
                handleSaveEntry(values.entries.find((v : Entry) => v.uuid === s.entryUUID))
            );
            await Promise.all(savePromises);
        }

        const url = new URL('/v1/timesheet/submit/' + timesheet.uuid, apiEndpoint);
        const response = await apiFetch(url.toString(), {
            method: 'PUT'
        });
        await response.json();
        await handleTimesheetSubmit(values);
        timesheet.entries.forEach(e => e.submitted = true);
        timesheet.submitted = true;

        setIsSubmitting(false);
    };

    const resolveUnsavedEntries = useCallback(async (values: FormikValues) => {
        const modifiedStates = entryStates.filter((e) => e.isModified);
        if (modifiedStates.length > 0) {
            const savePromises = modifiedStates.map((s) =>
                handleSaveEntry(values.entries.find((v : Entry) => v.uuid === s.entryUUID))
            );
            await Promise.all(savePromises);
        }
    }, [entryStates, handleSaveEntry]);
    
    const timesheetSubmitted = timesheet.submitted && !timesheet.entries.find(e => !e.submitted);

    return (
        <React.Fragment>
            <Formik
                initialValues={initialValues}
                validationSchema={schema}
                validateOnMount={true}
                onSubmit={submitTimeSheet}
                enableReinitialize={true}
                >
                {(props) => {
                return <Form>
                    <Row>
                       <Col xs={12} className="bg-white rounded p-4 mb-3">
                           <Row className="d-flex flex-column flex-lg-row w-100">
                               <Col xs={12} lg={6} className="d-flex flex-column">
                                   <h2>{timesheet.date.format(dateFormatter)}</h2>
                                   <div className="pb-2 pb-lg-0">
                                       Total Hours: <span className="text-dark">{totalHours}</span>
                                   </div>
                               </Col>
                               <Col xs={12} lg={6} className="d-flex flex-row justify-content-lg-end pr-0 mr-0">
                                   <ReactButton
                                       variant="primary"
                                       onClick={handleShowAddEntry}
                                       className="d-flex flex-row mr-2"
                                       disabled={loadType.loading && (loadType.type === 'add' || loadType.type === 'copy')}
                                   >
                                   {loadType.loading && (loadType.type === 'add' || loadType.type === 'copy') ? (
                                       <React.Fragment>
                                           <Spinner animation="border" size="sm" className="mt-1"/>
                                           &nbsp;Add Entry
                                       </React.Fragment>
                                   ) : (
                                       <React.Fragment>
                                           <FontAwesomeIcon icon={faPlus} className="pr-1 mt-1" />
                                           &nbsp;Add Entry
                                       </React.Fragment>
                                   )}
                                   </ReactButton>
                                   {timesheet.entries.length > 0 && (
                                       <React.Fragment>
                                           <ReactButton
                                               className={`d-flex flex-row justify-content-center btn cursor-pointer ${!props.isValid ? 'btn-submit-outline' : 'btn-submit'}`}
                                               disabled={!props.isValid || isSubmitting || timesheetSubmitted}
                                               title={!props.isValid ? 'You must fill the required fields below before you can submit.' : undefined}
                                               type={"submit"}
                                           >
                                               {isSubmitting ? (
                                                   <React.Fragment>
                                                       <Spinner animation="border" size="sm" className="mt-1"/>
                                                       &nbsp;Submitting
                                                   </React.Fragment>
                                               ) : (
                                                   <React.Fragment>
                                                       <FontAwesomeIcon icon={props.isValid ? faCheck : faExclamation} className="pr-1 mt-1" />
                                                       &nbsp;{timesheetSubmitted ? 'Submitted' : 'Submit'}
                                                   </React.Fragment>
                                               )}
                                           </ReactButton>
                                       </React.Fragment>
                                   )}
                               </Col>
                           </Row>
                           {(uniqueJobs.length < 1) && (
                               <Row className="d-flex flex-row w-100">
                                   <Col xs={12} className="d-flex flex-column align-items-center">
                                       <hr className="w-100"/>
                                       <img src={NoRecords} alt="No Records"/>
                                   </Col>
                               </Row>
                           )}
                           {uniqueJobs.map((jobNumber) => {
                              return <Job
                                  jobNumber={jobNumber}
                                  tasks={tasks}
                                  key={jobNumber + "-" + timesheet.uuid}
                                  values={props.values}
                                  handleRemoveEntry={handleRemoveEntry}
                                  formSetFieldValue={props.setFieldValue}
                                  jobs={jobs}
                                  handleAddEntry={handleAddEntry}
                                  timesheet={timesheet}
                                  handleSaveEntry={handleSaveEntry}
                                  entryStates={entryStates}
                                  loadType={loadType}
                                  resolveUnsavedEntries={resolveUnsavedEntries}
                                  updateTotalHours={updateTotalHours}
                                  hourLine={hourLines.filter((h) => h.jobNumber === jobNumber)}
                              />;
                           })}
                       </Col>
                    </Row>
                    <AddEntryModal
                        show={showAddEntry}
                        setShowAddEntry={setShowAddEntry}
                        jobs={jobs}
                        handleAddEntry={handleAddEntry}
                        timesheet={timesheet}
                        resolveUnsavedEntries={resolveUnsavedEntries}
                        values={props.values}
                    />
                </Form>
                }}
            </Formik>
        </React.Fragment>
    );
};

export default TimesheetForm;