import React, { useState} from 'react';
import {Button,  Modal} from "react-bootstrap";
import Form from 'react-bootstrap/Form';

type Props = {
    value: string,
    iteration: number,
    handelSaveNote: (note: string) => void,
    show: boolean,
    setShowNote: (showNote: boolean) => void
}

const NotesModel = ({value, iteration, handelSaveNote, show, setShowNote} : Props) => {
    const [currentNote, setCurrentNote] = useState<string>(value);
    const cancelSave = () => {
        setShowNote(false);
        setCurrentNote(value);
    }
    return (
        <>
            <Modal show={show} onHide={() => {cancelSave()}}>
                <Modal.Header closeButton>
                    <Modal.Title>Note for line item</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Row>
                            <Form.Label column={false}>Note</Form.Label>
                            <textarea
                                name="note"
                                className="form-control"
                                onChange={(e) => setCurrentNote(e.currentTarget.value)}
                                value={currentNote}
                            >{currentNote}</textarea>
                        </Form.Row>
                    </Form.Group>
                    <div className="d-flex flex-row w-100 justify-content-end">
                        <Button variant="secondary" onClick={() => {cancelSave()}} className="mr-2">
                            Cancel
                        </Button>
                        <Button variant="primary" onClick={() => {handelSaveNote(currentNote)}}>
                            Save
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default NotesModel;